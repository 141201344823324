import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.find.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  components: {},
  computed: _objectSpread({}, mapGetters(["currentPermission"])),
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32"
    }]);
    var permisison = {
      admin_exchange_rate: this.checkPermission("admin_exchange_rate", 1),
      admin_address: this.checkPermission("admin_address", 1),
      admin_delivery: this.checkPermission("admin_delivery", 0),
      admin_bank: this.checkPermission("admin_bank", 0),
      admin_china_thai: this.checkPermission("admin_china_thai", 0),
      admin_notification: this.checkPermission("admin_notification", 1),
      setting_notification_bar: this.checkPermission("setting_notification_bar", 0),
      admin_role: this.checkPermission("admin_role", 0),
      admin_address_china: this.checkPermission("admin_address_china", 1),
      admin_minimum_payshipping: this.checkPermission("admin_minimum_payshipping", 0),
      admin_tracking_warehouse: this.checkPermission("admin_tracking_warehouse", 0)
    };
    this.permisison = permisison;
  },
  data: function data() {
    return {
      permisison: {}
    };
  },
  methods: {
    getText: function getText() {
      var path = this.$route.path;

      switch (path) {
        case "/admin/setting/exchange-rate":
          return "อัตราแลกเปลี่ยน";

        case "/admin/setting/address":
          return "ตั้งค่าที่อยู่ผู้ส่ง";

        case "/admin/setting/thai-shipping":
          return "ขนส่งภายในประเทศ";

        case "/admin/setting/bank":
          return "บัญชีธนาคาร";

        case "/admin/setting/china-to-thai":
          return "ขนส่งจากจีนมาไทย";

        case "/admin/setting/role":
          return "สิทธิ์การใช้งาน";

        default:
          return "กรุณาเลือก";
      }
    },
    checkPermission: function checkPermission(name, type) {
      if (!this.currentPermission) {
        return false;
      }

      var permissionRole = this.currentPermission.permissionRole;
      var find = permissionRole.find(function (x) {
        return x.permissionInfo && x.permissionInfo.permissionName === name;
      });

      if (!find) {
        return false;
      }

      if (type === 0 && find.readableRole) {
        return true;
      }

      if (type === 1 && find.writeableRole) {
        return true;
      }

      return false;
    }
  }
};